/** 活动详情 */
<template>
  <div class="activity-details ">
    <template v-if="Object.keys(activityItem).length">
      <!-- 活动基本信息 -->
      <section class="back-fff mb-30 pb-30">
        <div class="content">
          <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/activity' }">活动专场</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'activity_list' }">活动专场列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div :class="['activity-basic', 'flex',{'flex-wrap':!$isPc}]">
            <el-image class="info-img border-r4" :src="$base+activityItem.activity_logo" fit="contain">
            </el-image>
            <div :class="['flex-1', 'flex-column', 'justify-center',{'plr-10':!$isPc}]">
              <h4 class="info-title">{{activityItem.activity_name}}</h4>
              <el-row type="flex" class="activity-info-list flex-wrap">
                <el-col class="activity-info-item">
                  <el-image :src="require('@/assets/images/home-activity1.png')"></el-image>
                  {{activityItem.activity_unit}}
                </el-col>
                <el-col class="activity-info-item">
                  <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                  {{activityItem.activity_address}}
                </el-col>
                <el-col class="activity-info-item">
                  <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                  {{activityItem.activity_date}}
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </section>
      <!-- 活动详情 -->
      <section class="back-fff pb-30">
        <div class="content">
          <div class="ipbs-title">
            <h4>活动详情</h4>
          </div>
          <div class="details-con" v-html="activityItem.activity_content"></div>
        </div>
      </section>
    </template>

    <el-empty v-else description="暂无该活动"></el-empty>
    <!-- 相关活动 -->
    <section v-if="$isPc" class="past-activity content pb-20">
      <div class="ipbs-title">
        <h4>相关活动</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/activity_list')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <el-row :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
        <el-col :xs="24" :sm="8" :lg="6" class="activity-item cursor-pointer mb-20"
          v-for="(item,index) in activityOther" :key="index">
          <router-link :to="{path:'/activity_details',query:{id:item.id}}" :class="{flex:!$isPc}">
            <el-image :class="['activity-img',{'flex-1':!$isPc}]" :src="$base+item.activity_logo" fit="cover">
            </el-image>
            <div :class="['activity-info', 'flex-column', {'justify-between':$isPc}, 'flex-1']">
              <h4 class="activity-title text-ellipsis">{{item.activity_name}}</h4>
              <el-row type="flex" class="activity-info-list" :gutter="10">
                <el-col :span="13" class="activity-info-item text-ellipsis">
                  <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                  {{item.activity_address}}
                </el-col>
                <el-col :span="11" class="activity-info-item text-ellipsis">
                  <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                  {{item.activity_date|filterTime}}
                </el-col>
              </el-row>
            </div>
          </router-link>
        </el-col>
      </el-row>
      <el-button type="info" class="ipbs-more">
        <router-link to="/activity_list">查看更多</router-link>
      </el-button>
    </section>
  </div>
</template>
<script>
import { getActivityDetails, getActivityOther, setReadactivity } from '@/api/activity'
export default {
  name: 'ActivityDetails',
  data () {
    return {
      activityItem: {},
      activityOther: {}
    }
  },
  watch: {
    '$route.query.id': {
      handler (newVal, oldVal) {
        this.getActivityDetails()
        this.getActivityOther()
      },
      deep: true
    }
  },
  mounted () {
    this.getActivityDetails()
    this.getActivityOther()
  },
  methods: {
    async getActivityDetails () {
      try {
        let { data } = await getActivityDetails(this.$route.query.id)
        this.activityItem = data
        this.setReadactivity()
      } catch (error) { }
    },
    async getActivityOther () {
      try {
        let { data } = await getActivityOther()
        this.activityOther = data
      } catch (error) { }
    },
    async setReadactivity () {
      try {
        await setReadactivity(this.$route.query.id)
      } catch (error) { }
    },
  }
}
</script>
<style lang="scss" scoped>
.activity-details {
  background-color: #f4f6fa;
}
.activity-basic {
  .info-img {
    max-width: 400px;
    margin-right: 40px;
  }
  .info-title {
    font-size: 24px;
    line-height: 26px;
  }
  .activity-info-list {
    margin-top: 40px;
    .activity-info-item {
      margin: 15px 0;
    }
  }
}

@media (max-width: 767px) {
  .activity-basic {
    .info-img {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .info-title {
      font-size: 18px;
      line-height: 22px;
    }
    .activity-info-list {
      margin-top: 15px;
      .activity-info-item {
        margin: 5px 0;
      }
    }
  }
}
</style>